export default class EventsIndex {

    constructor() {
        // this.dataTable = $('#basic-datatable').DataTable({
        //     "responsive": true,
        //     "columnDefs": [
        //         {
        //             "targets": -1,
        //             "orderable": false
        //         }
        //     ],
        //     order: [[0, 'asc'], [3, "asc"]],
        //     "language": {
        //         url: '/assets/data-tables/locales/' + I18n.locale + '.json',
        //         previous: "<i class='mdi mdi-chevron-left'>",
        //         next: "<i class='mdi mdi-chevron-right'>"
        //     },
        //     "lengthMenu": [
        //         [10, 25, 50, -1],
        //         [10, 25, 50, "All"]
        //     ],
        //     drawCallback:function(){
        //         $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
        //     }
        // });

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        //this.dataTable.onDestroy();
    }
}