import DefaultDataTable from "../utils/DefaultDataTable";

export default class AdminsIndex {

    constructor() {
        this.dataTable = new DefaultDataTable();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.dataTable.onDestroy();
    }
}