export default class EventManagersForm {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;

        $("#event_manager_app_ids").select2({
            language: "fr"
        });

        this.updateFields();

        $("#event_manager_global_view").change(function (){
            self.updateFields();
        })

    }

    updateFields(){
        if($("#event_manager_global_view").prop("checked")){
            $("#event_manager_app_ids").parent().parent().addClass("d-none")
        }else{
            $("#event_manager_app_ids").parent().parent().removeClass("d-none")
        }
    }

    onDataChanged(data) {
    }

    onDestroy() {
        $("#event_manager_app_ids").select2('destroy')
    }

}