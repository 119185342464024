export default class EventsListObjectTypes {


    constructor() {

        this.object_type_index = $(".event_map_object_type_item").length;

        this.bindEvents();
    }

    bindEvents() {
        var self = this;
        $("#add_event_map_object_type_btn").off("click").on("click", function(e) {
            e.preventDefault();
            var item = $(".event_map_object_type_item_template").clone();

            $("#no_object_alert").addClass("d-none");


            item.removeClass("event_map_object_type_item_template").removeClass("d-none");
            item.addClass("event_map_object_type_item");

            $.each(item.find("input.initial"), function(idx, input) {
                console.log(input);
                $(input).attr("name", $(input).data("name").replace("__ID__", self.object_type_index));
            });

            $.each(item.find("span.initial"), function(idx, span) {
                $(span).attr("idx", $(span).data("id").replace("__ID__", self.object_type_index));
            });

            $.each(item.find(".select-icon-btn"), function(idx, btn) {
                $(btn).data("icon-field", $(btn).data("icon-field").replace("__ID__", self.object_type_index));
                $(btn).attr("preview-icon", $(btn).data("preview-icon").replace("__ID__", self.object_type_index));
            });

            item.appendTo($("#event_map_object_type_list"));

            self.object_type_index++;

            $(".delete-event-map-object-type-item-btn").off("click").on("click", function(e) {
                e.preventDefault();

                $(this).parent().parent().parent().remove();

                if($("#event_map_object_type_list").find(".event_map_object_type_item").length==0) {
                    $("#no_object_alert").removeClass("d-none");
                }
            });

            self.bindSelectIcon($(item).find(".select-icon-btn"));
        });

         $.each($(".select-icon-btn"), function(idx, elem) {
            self.bindSelectIcon(elem);
        });

        $("#search-icon").off("keyup").on("keyup", function(e) {
            self.searchIcons();
        });

        $(".btn-icon-select").off("click").click(function(e) {
            var icon = $(this).data("icon-key");
            // console.log(this);
            // console.log(icon);
            // console.log($("#icon_modal").data("preview-icon"));
            $($("#icon_modal").data("preview-icon")).html('<i class="fa fa-'+icon+'"></i>');
            $('input[name="'+$("#icon_modal").data("icon-field")+'"]').val(icon);
            $("#icon_modal").modal("hide");
            $("#search-icon").val("");
        });

    }

    searchIcons() {
        var search = $("#search-icon").val();

        console.log(search);
        if(search.length==0) {
            $(".icon-item").show();
        }
        else {
            $(".icon-item").hide();
            $('.icon-item[data-term*="'+search+'"]').show();    
        }
       
    }

    bindSelectIcon(elem) {
        $(elem).click(function(e) {
            $("#icon_modal").data({
                "icon-field": $(elem).data("icon-field"),
                "preview-icon": $(elem).data("preview-icon")
            }).modal();
        });
    }
}