export default class ConfirmationModal {

    constructor() {
    }

    displayConfirm(title, message, opts) {
        let $confirm_modal = $("#confirm_modal");
        $confirm_modal.find(".modal-title").html(title);
        $confirm_modal.find(".modal-body").html(message);

        var modalType = (opts!=null && opts.modal_type!=null) ? opts.modal_type : "normal";
        if(modalType==="large") {
            $confirm_modal.find(".modal-dialog").addClass("modal-lg");
        }
        else {
            $confirm_modal.find(".modal-dialog").removeClass("modal-lg");
        }


        if(opts.positive_btn!=null) {
            var p_type = opts.positive_btn.type;
            if(p_type==null) {
              p_type = "primary";
            }
            $confirm_modal.find(".positive-btn").attr("class", "btn waves-effect waves-light positive-btn btn-"+p_type).html(opts.positive_btn.label).off("click").on("click", function(e) {
                if(opts.positive_btn.callback!=null) {
                    opts.positive_btn.callback();
                }
                $confirm_modal.modal("hide");

            }).show();
        }
        else {
            $confirm_modal.find(".positive-btn").off("click").hide();
        }

        if(opts.negative_btn!=null) {
            var n_type = opts.negative_btn.type;
            if(n_type==null) {
              n_type = "white";
            }
            $confirm_modal.find(".negative-btn").attr("class", "btn waves-effect waves-light negative-btn btn-"+n_type).html(opts.negative_btn.label).off("click").on("click", function(e) {
                if(opts.negative_btn.callback!=null) {
                    opts.negative_btn.callback();
                }
                $confirm_modal.modal("hide");
            }).show();
        }
        else {
            $confirm_modal.find(".negative-btn").off("click").hide();
        }

        $confirm_modal.modal({ backdrop: "static"});
    }
}