import I18n from "i18n-js";

export default class EventsForm {


    constructor() {

        this.initial_latitude = $("#event_map").data("initialLatitude");
        this.initial_longitude = $("#event_map").data("initialLongitude");

        this.event_latitude = $("#event_map").data("eventLatitude");
        this.event_longitude = $("#event_map").data("eventLongitude");

        this.event_marker = null;
        this.map = null;

        this.dropify = null;

        //this.layer = null;
        this.layers = [];
        this.drawnItems = null;

        if(this.event_latitude!=null) {
            $("#event_map").removeClass("d-none");
            this.initMap();
        }

        this.bindEvents();

        if($("#event_zone_config").val().length>0) {
            $("#save_event").removeAttr("disabled");
        }
    }


    bindEvents() {

        var self = this;

        $("#minus_radius_btn").off("click").click(function(e) {
            e.preventDefault();
            $("#event_max_radius_meters").val(parseInt($("#event_max_radius_meters").val())-25);

        });

        $("#plus_radius_btn").off("click").click(function(e) {
            e.preventDefault();
            $("#event_max_radius_meters").val(parseInt($("#event_max_radius_meters").val())+25);
        });

        $("#update_geolocation_btn").off("click").click(function(e) {
            e.preventDefault();

            self.searchGeolocation();
        });


        $("#event_event_manager_id").select2({
            language: "fr",
            maximumSelectionLength: 2,
            minimumInputLength: 2,
            ajax: {
                url: '/event_managers.json',
                quietMillis: 200,
                processResults: function (data) {

                    var results = [];
                    for(var i =0;i<data.length;i++) {
                        results.push({
                            id: data[i].id,
                            text: data[i].first_name+" "+data[i].last_name+"("+data[i].email+")"
                        });
                    }
                  return {
                    results: results
                  }
                }
            }
        });


        $("#event_app_id").select2({
            language: "fr",
            maximumSelectionLength: 2,
            minimumInputLength: 2,
            ajax: {
                url: '/apps.json',
                quietMillis: 200,
                processResults: function (data) {

                    var results = [];
                    for(var i =0;i<data.length;i++) {
                        results.push({
                            id: data[i].id,
                            text: data[i].name
                        });
                    }
                  return {
                    results: results
                  }
                }
            }
        });

        $(".manager-select").select2({
          multiple: true,
          // customizeing the cross
          templateSelection: function (data, container) {
            return $('<span class="badge badge-primary ml-2">'+data.text+'</span>');
          },
          width: '100%',
          language: "fr",
          closeOnSelect: false,
          maximumSelectionLength: 2,
        });

        this.dropify = $('.dropify').dropify({
          messages: {
              'default': I18n.t('plugins.dropify.messages.default'),
              'replace': I18n.t('plugins.dropify.messages.replace'),
              'remove':  I18n.t('plugins.dropify.messages.remove'),
              'error':   I18n.t('plugins.dropify.messages.error')
          },
          error: {
              'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
              'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
              'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
              'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
              'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
              'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
              'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
          }});
          
          $("#language-select").select2({});
            

          $(".language-fields").hide();
          $("#language-fields-"+$("#language-select").val()).show();
          
          $("#language-select").on("change", function() {
            var language = $(this).val();
            $(".language-fields").hide();
            $("#language-fields-"+language).show();
          });
    }

    searchGeolocation() {
        console.log("searchGeolocation");
        var self = this;

        $.ajax({
            method: "GET",
            url: "/events/geo",
            data: {
                address: $("#event_address_line1").val(),
                postal_code: $("#event_postal_code").val(),
                city: $("#event_city").val()
            }
        }).done(function(json) {
            console.log(json);
            if(json.success && json.lat!=null && json.lon!=null) {
                self.updateGeolocation(json.lat, json.lon);

                if(self.map==null) {
                    $("#event_map").removeClass("d-none");
                    self.initMap();
                }
                self.map.setView([json.lat, json.lon], 15);
            }else {
              application.alertNotification(I18n.t("backoffice.events.form.address_not_found"));
              $("#event_map").addClass("d-none");
              return;
            }
        });
    }

    initMap() {
        var self = this;
        $('#event_map').height(500);
        this.map = L.map('event_map').setView([this.initial_latitude, this.initial_longitude], 15);

        this.drawnItems = new L.FeatureGroup().addTo(this.map);
        //this.map.addLayer(this.drawnItems);
        var drawControl = new L.Control.Draw({
            draw: {
                polyline: false,
                polygon: {},
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
            },
            edit: {
                featureGroup: this.drawnItems
            }
        });
        this.map.addControl(drawControl);


        this.map.on(L.Draw.Event.CREATED, function (event) {
            console.log("event created called");
            // if(self.layer!=null) {
            //     self.drawnItems.removeLayer(self.layer);
            // }

            self.layers.push(event.layer);

            //layer.getCenter()

            self.drawnItems.addLayer(event.layer);

            self.updateZoneConfig();
        });

        this.map.on(L.Draw.Event.EDITED, function (event) {
            console.log("event edited called");
            if(self.layers.length==0) {
                console.log("setting layer");
                
                self.layers = event.layers.getLayers();
            }
            console.log(event);
            self.updateZoneConfig();
        });

        this.map.on(L.Draw.Event.DELETED, function (event) {
            console.log("event deleted called");
            console.log(event.layers);
            self.layers = self.drawnItems.getLayers();
            console.log("layers -> ");
            console.log(self.layers);
            self.updateZoneConfig();
        });


        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
        /*this.map.on('click', function(e) {
            self.addMarker(e);
        });*/

        // if(this.event_latitude!=null) {
        //     this.event_marker = L.marker([this.event_latitude, this.event_longitude]);
        //     this.event_marker.addTo(this.map);
        // }

        this.initLayer();
    }

    initLayer() {
        var event_zone_config = $("#event_zone_config").val();

        if(event_zone_config.length>0 && event_zone_config != '"Sample zone config"') {
            var data = JSON.parse(event_zone_config);
            if(data.type!=undefined) {
                data = [data];
            }

            var self = this;
            
            $.each(data, function(idx, _data) {
                console.log(_data);

                let __data = _data
                if( Array.isArray(__data) ) {
                    __data = _data[0]
                }

                if(__data.type!=undefined) {
                    var layer = null;
                    if(__data.type=="Polygon") {
                        layer = L.polygon(__data.coordinates, {
                            weight: 5,
                            opacity: 1.0
                        });
                        layer.addTo(self.drawnItems);
                    }
                    else if (__data.type=="Circle")  {
                        layer = L.circle(__data.coordinates, {
                            radius: __data.radius,
                            weight: 5,
                            opacity: 1.0
                        });
                        layer.addTo(self.drawnItems);
                    }
                    self.layers.push(layer);
                }
            });
            if(self.drawnItems.getLayers().length>0) {
                self.map.fitBounds(self.drawnItems.getBounds());
            }
        }
    }

    updateZoneConfig() {
        //var center = this.layer.getCenter();

        var finalData = [];

        for(var l=0;l<this.layers.length;l++) {
            var layer = this.layers[l];

            var geojson = layer.toGeoJSON();
            console.log(geojson);
            var _data = {};


            if(geojson["geometry"]["type"]=="Polygon") {
                console.log("layer is polygon");
                var _latLngs = [];

                var coordinates = geojson["geometry"]["coordinates"];

                for(var i =0;i<coordinates[0].length;i++) {
                    _latLngs.push([coordinates[0][i][1], coordinates[0][i][0]]);
                }

                _data["type"] = "Polygon";
                _data["coordinates"] = [_latLngs];

                var center = layer.getCenter();
            }
            else {
                console.log("layer is circle");
                _data["type"] = "Circle";
                _data["coordinates"] =  [geojson["geometry"]["coordinates"][1], geojson["geometry"]["coordinates"][0]];
                _data["radius"] = layer.getRadius();
            }
            console.log("add layer to final data");
            finalData.push(_data);
        }

        console.log(finalData);

        this.rawData = finalData;

        $("#event_zone_config").val(JSON.stringify(finalData));
        $("#save_event").removeAttr("disabled");
    }

    /*
    addMarker(e) {
        this.updateGeolocation(e.latlng.lat, e.latlng.lng)        
    }

    */

    updateGeolocation(lat, lon) {
        console.log("updateGeolocation");
        console.log(this);
        // if(this.event_marker!=null) {
        //     this.event_marker.remove();
        // }
        this.event_latitude = parseFloat(lat);
        this.event_longitude = parseFloat(lon);
        $("#event_latitude").val(this.event_latitude);
        $("#event_longitude").val(this.event_longitude);
        //this.event_marker = L.marker([this.event_latitude, this.event_longitude]);
        //this.event_marker.addTo(this.map);
    }

    onDestroy() {
      $("#event_event_manager_id").select2("destroy");
      $("#event_app_id").select2("destroy");
      $(".manager-select").select2("destroy");
      $("#language-select").select2("destroy");   
    }
}