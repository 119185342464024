export default class EventsEditOpeningHours {


    constructor() {

        this.opening_hour_item_index = $(".opening_hour_item").length;

        this.bindEvents();
    }

    bindEvents() {
        var self = this;
        $("#add_opening_hour_btn").off("click").on("click", function(e) {
            e.preventDefault();
            var item = $(".opening_hour_item_template").clone();

            $("#no_opening_hour_alert").addClass("d-none");


            item.removeClass("opening_hour_item_template").removeClass("d-none");
            item.addClass("opening_hour_item");

            item.find(".add-opening-day-btn").data("index", self.opening_hour_item_index);

             $.each(item.find("input.initial"), function(idx, input) {
                console.log(input);
                $(input).attr("name", $(input).data("name").replace("__ID__", self.opening_hour_item_index));
            });

            item.appendTo($("#opening_hours_list"));

            self.opening_hour_item_index++;

            self.bindOpeningHourItems();
        });

        this.bindOpeningHourItems();

    }

        bindOpeningHourItems() {
        $(".delete-opening-hour-item-btn").off("click").on("click", function(e) {
            e.preventDefault();

            $(this).parent().parent().parent().remove();

            if($("#opening_hours_list").find(".opening_hour_item").length==0) {
                $("#no_opening_hour_alert").removeClass("d-none");
            }
        });

        $(".delete-hour-item-btn").off("click").on("click", function(e) {
            e.preventDefault();

            $(this).parent().parent().remove();
        });

        $(".add-opening-day-btn").off("click").on("click", function(e) {
            e.preventDefault();
            var tableBody = $(this).parent().parent().find("table tbody");

            var _count = parseInt(tableBody.data("opening-hour-count"));
            tableBody.data("opening-hour-count", _count+1);

            var item = tableBody.find(".copyable-opening-item").clone();

            var index = $(this).data("index");

            item.removeClass("copyable-opening-item").removeClass("d-none");
            item.addClass("opening-item");
            item.appendTo(tableBody);

            item.find(".delete-hour-item-btn").off("click").on("click", function(e) {
                e.preventDefault();

                $(this).parent().parent().remove();
            });

            $.each(item.find(".oi-input"), function(idx, input) {
                $(input).attr("name", $(input).data("name").replace("__ID__", index).replace("__IDX__", _count));
            });

        });

    }

}