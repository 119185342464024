export default class AppsIndex {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        var self = this;
        $(".js-app-switch").unbind("change").change(function(e) {
            e.preventDefault();
            self.update_is_enabled(this);
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
        $(".app_switch").unbind("change");
    }

    update_is_enabled(obj){
        this.update_app($(obj).data("id"), 'is_enabled', $(obj).is(':checked'))
    }

    update_app(id, field_name, value){
        let data = {
            app: {
                is_enabled: value
            }
        };

        let ajax_data = {
            url: "/apps/"+id+".json",
            data: data,
            method: "put",
            success: function (data){
                if(data.app.is_enabled) {
                    application.successNotification(I18n.t("backoffice.apps.index.app_enabled", { name: data.app.name }));
                }
                else {
                    application.alertNotification(I18n.t("backoffice.apps.index.app_disabled", { name: data.app.name }));
                }
            },
            error: function (err){
                
            }
        }

        $.ajax(ajax_data)
    }
}