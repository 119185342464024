export default class AppsForm {


    constructor() {

        this.bindEvents()
    }


    bindEvents() {

        $('#app_large_icon').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
                'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
            }});

        $('#app_default_image').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
                'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
            }});


    $("#app_event_manager_owner_id").select2({
        language: "fr",
        maximumSelectionLength: 2,
        minimumInputLength: 2,
        ajax: {
            url: '/event_managers.json',
            quietMillis: 200,
            processResults: function (data) {

                var results = [];
                for(var i =0;i<data.length;i++) {
                    results.push({
                        id: data[i].id,
                        text: data[i].first_name+" "+data[i].last_name+"("+data[i].email+")"
                    });
                }
              return {
                results: results
              }
            }
        }
      });
    }
}