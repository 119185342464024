import DefaultDataTable from "../utils/DefaultDataTable";

export default class RoleEdit {

    constructor() {
        this.initCheckboxes();
        this.bindEvents();
    }


    bindEvents() {
        $("input[id^=controller_][type=checkbox]").unbind("change").change(this.checkActionsByController);
        $("input[id^=action_][type=checkbox]").unbind("change").change(this.checkControllerByActions);
    }

    onDataChanged(data) {
    }

    onDestroy() {
        $("input[id^=controller_][type=checkbox]").unbind("change");
        $("input[id^=action_][type=checkbox]").unbind("change")
    }

    initCheckboxes() {
        $("input[id^=action_][type=checkbox]").each(this.checkControllerByActions)
    }

    checkActionsByController() {
        let controller = $(this).data("controller");
        let is_checked = $(this).is(":checked");
        $("input[id^=action_"+controller+"]").each(function (){
            if(is_checked){
                $(this).prop("checked", true);
            }else{
                $(this).prop("checked", false);
            }
        });
    }

    checkControllerByActions() {
        let controller = $(this).data("controller");
        let all_checked = true;
        $("input[id^=action_"+controller+"]").each(function (){
            if(!$(this).is(":checked")){
                all_checked = false;
                return false;
            }
        })

        if(all_checked){
            $("input[id^=controller_"+controller+"][type=checkbox]").prop("checked", true);
        }else{
            $("input[id^=controller_"+controller+"][type=checkbox]").prop("checked", false);
        }
    }
}