export default class Routes {


    static routes = {};


    constructor() {
        this.routes = Routes.routes;
    }

    getController(controller, action){
        return this.existController(controller, action) ? new this.routes[controller+action]() : null;
    }

    existController(controller, action){
        return this.routes[controller+action] !== undefined
    }

}