import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/DashboardIndex";
import PermissionsIndex from "../controllers/PermissionsIndex";
import AdminsIndex from "../controllers/AdminsIndex";
import AdminsEdit from "../controllers/AdminsEdit";
import AdminsNew from "../controllers/AdminsNew";
import RoleIndex from "../controllers/RoleIndex";
import RoleEdit from "../controllers/RoleEdit";
import ProfileIndex from "../controllers/ProfileIndex";
import EventsForm from "../controllers/EventsForm";
import EventsIndex from "../controllers/EventsIndex";
import EventsEditOpeningHours from "../controllers/EventsEditOpeningHours";
import EventsListObjectTypes from "../controllers/EventsListObjectTypes";
import EventsEditObjectsForm from "../controllers/EventsEditObjectsForm";
import AppsIndex from "../controllers/AppsIndex";
import AppsForm from "../controllers/AppsForm";
import EventManagersForm from "../controllers/EventManagersForm";

export default class Routes extends ConfigRoutes{

    static routes = {
        "DashboardIndex": DashboardIndex,
        "PermissionsIndex": PermissionsIndex,
        "AdminsIndex": AdminsIndex,
        "AdminsEdit": AdminsEdit,
        "AdminsUpdate": AdminsEdit,
        "AdminsNew": AdminsNew,
        "AdminsCreate": AdminsNew,
        "RolesIndex": RoleIndex,
        "RolesEdit": RoleEdit,
        "RolesNew": RoleEdit,
        "RolesUpdate": RoleEdit,
        "RolesCreate": RoleEdit,
        "ProfileIndex": ProfileIndex,
        "EventsIndex": EventsIndex,
        "EventsPartnersShow": EventsIndex,
        "EventsNew": EventsForm,
        "EventsEdit": EventsForm,
        "EventsCreate": EventsForm,
        "EventsUpdate": EventsForm,
        "EventsEditOpeningHours": EventsEditOpeningHours,
        "EventsListObjectTypes": EventsListObjectTypes,
        "EventsEditObjects": EventsEditObjectsForm,
        "AppsIndex": AppsIndex,
        "AppsNew": AppsForm,
        "AppsEdit": AppsForm,
        "AppsCreate": AppsForm,
        "AppsUpdate": AppsForm,
        "EventManagersEdit": EventManagersForm,
        "EventManagersNew": EventManagersForm,
        "EventManagersCreate": EventManagersForm,
        "EventManagersUpdate": EventManagersForm,
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}