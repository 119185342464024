import ConfigRoutes from "./Routes";

export default class Application {

    constructor() {
        this.routes = new ConfigRoutes();
        this.controller_name = null;
        this.action_name = null;
        this.current_handler = null;
        this._dataChangedListener = null;

        this.bindEvents();
    }


    bindEvents() {
        let self = this;
        $(document).off("turbolinks:before-cache").on('turbolinks:before-cache', function(e) {
            $(document).off("Application::PageChanged");
            if (self.current_handler != null && self.current_handler.onDestroy !== undefined){
                self.current_handler.onDestroy();
            }
        });
    }

    setController(controller) {
        this.controller_name = controller;
    }

    setAction(action) {
        this.action_name = action;
    }

    setData(data) {
        this.data = data;
        if(this._dataChangedListener!=null) {
            this._dataChangedListener.onDataChanged(data);
        }
    }

    setOnDataChangeListener(listener) {
        this._dataChangedListener = listener;
    }

    getMeta(name){
        return $("meta[name="+name+"]").attr("content")
    }

    pageChanged() {
        this._dataChangedListener = null;
        this.current_handler = this.routes.getController(this.camelize(this.controller_name), this.camelize(this.action_name));
        this.setData(window.data);
        this.bindEventsAfterPageChanged();
    }

    bindEventsAfterPageChanged() {
        let self = this;
    }

    camelize(string) {
        let string_camelized =  string.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
        return this.capitalize(string_camelized)
    };

    capitalize(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    addData(object){
        if(window.data === undefined || window.data === null){
            window.data = {};
        }
        window.data= Object.assign(window.data, object)
    }

}