export default class EventsEditObjectsForm {


    constructor() {
        this.event_latitude = $("#event_map").data("eventLatitude");
        this.event_longitude = $("#event_map").data("eventLongitude");
        this.zone_config = $("#event_map").data("eventZoneConfig");

        this.eventZones = new L.FeatureGroup();

        this.objectTypes = {};

        this.current_object_type = null;
        this.current_object = null;
        this.objects = {}

        this.event_marker = null;
        this.map = null;

        this.layer = null;
        this.drawnItems = null;
        this.labels = null;

        if(this.event_latitude!=null) {
            this.initMap();
        }

        this.bindEvents();
    }


    bindEvents() {
        var self = this;
        $(".add-map-object-type-btn").unbind("click").click(function(e) {
            e.preventDefault();
            self.addObject($(this).data());
        });

        $.each($("#event_map").data("eventObjectTypes"), function(idx, objectType) {
            self.objectTypes[objectType.id] = objectType;
            self.objectTypes[objectType.id].drawer = new L.Draw.Polygon(self.map);
         });

        if($("#event_map").data("eventObjects").length>0) {
            this.initObjects();
        }
    }

    initObjects() {
        var self = this;
        var _objects = $("#event_map").data("eventObjects");

        for(var i=0; i<_objects.length;i++) {
            var _object = _objects[i];
            console.log(_object);
            var objectType = this.objectTypes[_object.event_map_object_type_id];
            var layer = L.polygon(_object.coordinates, {
                weight: 5,
                color: objectType.color
            });
            this.drawnItems.addLayer(layer).addTo(this.map);
            console.log("un objet ^.^");

            self.objects[layer._leaflet_id] = { layer: layer, object_type: _object.event_map_object_type_id, id: _object.id }

        }

        this.updateObjects();
    }

    addObject(data) {

        console.log(data.color);
        this.objectTypes[data.id].drawer.setOptions({ shapeOptions: { color: data.color, weight: 5 } });
        this.objectTypes[data.id].drawer.enable();
        this.current_object_type = data.id;
        this.current_object = null;

        /*var label1 = new L.tooltip({content: '<i class="mdi mdi-food"></i> Restaurant', direction: "top"});
        label1.setLatLng(polygon1.getBounds().getCenter());
        label1.addTo(map);*/
    }

    initMap() {
        var self = this;
        $('#event_map').height(500);
        this.map = L.map('event_map').setView([this.event_latitude, this.event_longitude], 15);

        this.drawnItems = new L.FeatureGroup().addTo(this.map);
        this.labels = new L.FeatureGroup().addTo(this.map);

        var drawControl = new L.Control.Draw({
            draw: {
                polyline: false,
                polygon: false,
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
            },
            edit: {
                featureGroup: this.drawnItems
            }
        });
        this.map.addControl(drawControl);


        this.map.on(L.Draw.Event.CREATED, function (event) {
             var layer = event.layer;

            self.drawnItems.addLayer(layer);

            self.objects[layer._leaflet_id] = { layer: layer, object_type: self.current_object_type }

            self.current_object_type = null;

            self.updateObjects();
        });

        this.map.on(L.Draw.Event.EDITED, function (event) {

            console.log(event);
            self.updateObjects();
        });

        this.map.on(L.Draw.Event.DELETED, function (event) {

            for(var i=0; i<Object.keys(event.layers._layers).length;i++) {
                console.log(Object.keys(event.layers._layers)[i]);
                delete self.objects[Object.keys(event.layers._layers)[i]];
            }

            self.updateObjects();
        });


        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxNativeZoom: 19, maxZoom: 23,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
        this.initLayer();
    }

    initLayer() {
        console.log("initLayer");
        var zones = this.zone_config;

        this.eventZones.addTo(this.map);

        if(zones.type!=undefined) {
            zones = [zones];
        }

        for(var l=0;l<zones.length;l++) {
            var zone = zones[l];

            console.log(zone);
            if(zone.type=="Polygon") {
                this.eventZones.addLayer(L.polygon(zone.coordinates, {
                    weight: 3,
                    opacity: 0.2
                }));
            }
            else {
                this.eventZones.addLayer(L.circle(zone.coordinates, {
                    radius: zone.radius,
                    weight: 3,
                    opacity: 0.2
                }));
            }
        }

        this.map.fitBounds(this.eventZones.getBounds());
    }

    updateObjects() {
        var self = this;
        var _data = [];
        console.log("updateObjects");

        //clear labels
        this.labels.clearLayers();

        $.each(this.objects, function(index, object) {
            console.log(object);
            console.log(self.objectTypes[object.object_type])

            _data.push({
                id: object.id,
                event_map_object_type: object.object_type,
                coordinates: object.layer._latlngs[0]
            });

            //add label
            var label1 = new L.tooltip({content: '<i class="fas fa-'+self.objectTypes[object.object_type].icon+'"></i> ', direction: "top", permanent: true});
            label1.setLatLng(object.layer.getBounds().getCenter());
            self.labels.addLayer(label1);
        });
        console.log(" end for each");

        $("#json_objects").val(JSON.stringify(_data));
    }

}